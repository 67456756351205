<template>
  <VueTelInput
    ref="root"
    :inputOptions="{
      styleClasses: { 'invalid-answer': phone && !isValid },
      type: 'tel',
      placeholder,
      required,
    }"
    :styleClasses="{ 'invalid-answer': phone && !isValid }"
    v-model="phoneNumber"
    @blur="onBlur"
    @input="onPhoneChange"
    @validate="onValidateChange"
    @open="$emit('open')"
    @close="$emit('close')"
  />
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'
/* Todo - add form validation message */
export default {
  components: { VueTelInput },
  props: {
    phone: String,
    placeholder: String,
    required: Boolean,
    invalidEmptyMessage: String,
    invalidMessage: String,
  },
  data() {
    return {
      phoneNumber: '',
      isValid: false,
    }
  },
  watch: {
    phone: {
      handler(v) {
        this.phoneNumber = v
      },
      immediate: true,
    },
  },
  mounted() {
    this.getInput()
  },
  methods: {
    onValidateChange(e) {
      this.$emit('validated', [e.number, e.valid])
      this.isValid = e.valid
    },
    onPhoneChange(phone, e) {
      this.getInput()
      this.$emit('phone', e.number)
    },
    getValidationMessage() {
      const isValid = this.isValid
      if (!this.phoneNumber && this.invalidEmptyMessage) return this.invalidEmptyMessage
      if (this.phoneNumber && this.invalidMessage) return this.invalidMessage
      return isValid ? '' : 'Please enter a valid phone number.'
    },
    getInput() {
      const input = this.$refs.root.$el.querySelectorAll('input[type=tel]')[0]
      if (input) input.setCustomValidity(this.getValidationMessage())
      return input
    },
    onBlur() {
      this.$emit('blur')
    },
  },
}
</script>
